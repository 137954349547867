import { copy } from 'angular';
import ReviewDrugController from '../services/review-dialog/review-dialog.controller';
import { RESOURCE_ITEM_FIELD_TYPES } from 'common/resource/module.constants';

const DEFAULT_LIST_FIELDS = {
  name: 'Name',
  concentration: 'Concentration',
};

const DEFAULT_PROFILE_FIELDS = [
  {
    key: 'concentration',
    label: 'Concentration',
    type: RESOURCE_ITEM_FIELD_TYPES.item,
  },
  {
    key: 'dose_per_unit',
    label: 'Total Dose Per Unit',
    type: RESOURCE_ITEM_FIELD_TYPES.item,
  },
  {
    key: 'measurement_type',
    label: 'Measurement Type',
    type: RESOURCE_ITEM_FIELD_TYPES.item,
  },
  {
    key: 'form',
    label: 'Form',
    type: RESOURCE_ITEM_FIELD_TYPES.item,
  },
  {
    key: 'unit_volume',
    label: 'Unit Volume',
    type: RESOURCE_ITEM_FIELD_TYPES.item,
  },
  {
    key: 'route.name',
    label: 'Route',
    type: RESOURCE_ITEM_FIELD_TYPES.item,
  },
  {
    key: 'is_controlled_drug',
    label: 'Controlled Drug',
    type: RESOURCE_ITEM_FIELD_TYPES.radio,
  },
  {
    key: 'exclude_from_stock',
    label: 'Exclude From Stock',
    type: RESOURCE_ITEM_FIELD_TYPES.radio,
  },
];

export default function NonFormularyDrugListController (
  DrugResource, $mdSidenav, Dialog, Toast, Utils
) {
  'ngInject';

  this._mdSidenav = $mdSidenav;
  this._Dialog = Dialog;
  this._Toast = Toast;
  this._Utils = Utils;

  this.singularName = 'Non-formulary Drug';
  this.pluralName = 'Non-formulary Drugs';
  this.editButtonLabel = 'Review';

  this.reviewDialogController = ReviewDrugController;
  this.reviewDialogTemplateUrl = 'pages/admin/drugs/services/review-dialog/review-dialog.tpl.html';

  DrugResource
    .index({ include: 'productCodes.supplier,stockLocationProperties', onlyNonFormulary: 1 })
    .then(items => { this.items = items; });

  this.relationData = { drugs: [] };
  DrugResource.index().then(items => { this.relationData.drugs = items; });

  this.hasProfiles = true;
  this.listFields = Object.assign({}, DEFAULT_LIST_FIELDS);
  this.profileFields = [...DEFAULT_PROFILE_FIELDS];

  this.listButtonAction = (evt, item) => {
    this.showSidenav(item);
  };

  this.showSidenav = (item) => {
    if (!this.hasProfiles) {
      return;
    }
    this.selectedItem = item;
    this._mdSidenav('resource-item').open();
  };

  this.showReviewDialog = (evt, data) => {
    if (this.hasProfiles) {
      this._mdSidenav('resource-item').close();
    }

    let editing = !!data;

    let dialogParams = {
      controller: this.reviewDialogController,
      locals: {
        editing: editing,
        item: copy(data),
        items: this.items,
        relationData: this.relationData,
      },
      targetEvent: evt,
      templateUrl: this.reviewDialogTemplateUrl,
    };

    this._Dialog.show(dialogParams).then((item) => {
      this._Utils.removeFromArrayById(this.items, item.id);
      this._Toast.showSimple('Non-formulary Drug approved.');
    }).catch((err) => {
      if (err.status) {
        this._Toast.showSimple('Error: could not approve drug.');
      }
    });
  };
};
