import { toDateTimeString } from 'common/utils/services/dateTime';

export default function parse (item) {
  return {
    id: item.id,
    drug_id: item.drug.id,
    dose: item.dose,
    route_id: item.route.id,
    time: toDateTimeString(item.date, item.time),
  };
}
